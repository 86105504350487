<template>
  <div>
    <div
      v-for="(sideBarOption, sIndex) in sideBarOptions"
      :key="'system-admin-side-bar-item' + sIndex"
      class="py-2 px-3 text-sm text-center mb-1 cursor-pointer rounded-sm transition-all duration-200"
      :class="{
        'bg-primary-gray text-white': sideBarOption.label === selectedOption.label
      }"
      @click="setSelectedOption(sideBarOption)"
    >
      {{ sideBarOption.label }}
    </div>
  </div>
</template>

<script>
import useSystemAdminSideBar from './systemAdminSidebar.js';

export default {
    name: 'SystemAdminSideBar',

    setup () {
        const { selectedOption, setSelectedOption, sideBarOptions } = useSystemAdminSideBar();

        return {
            selectedOption,
            setSelectedOption,
            sideBarOptions
        };
    }
};
</script>
